/**
 * Entry point for webpack
 * Import styles, fonts, icons, scripts and other things that your project design requires here
 */

import objectFitImages from 'object-fit-images'
import svg4everybody from 'svg4everybody'
// import MicroModal from 'micromodal'

import './styles/_fonts.scss'
import './styles/_reset.scss'
import './styles/main.scss'
import './styles/_form.scss'
import './styles/_cta.scss'
import './styles/_atomic.scss'
import './styles/_button.scss'
import './styles/_title.scss'
import './styles/_text.scss'

import './styles/blocks/_dropdown.scss'
import './styles/blocks/_cover.scss'
import './styles/blocks/_section.scss'
import './styles/blocks/_modal-form.scss'
import './styles/blocks/_privacy.scss'

import './styles/animations/_cover.scss'

import './styles/blocks/_base-animations.scss'

import form from './form'
import gsapAnimations from './scripts/gsap-animations'
import dropdown from './scripts/dropdown'
import videoObserver from './scripts/video-observer'
import loadingAnimation from './scripts/loading-animation'
// import staticCovers from './scripts/change-static-cover'
// import previewObserver from './scripts/preview-section-observer'
// import changeImageOnHover from './scripts/image-change'
// import sliderButtonStatus from './scripts/slider-button-clicked-status'
import activateModalForm from './scripts/modal-form-activate'
// import tabs from './scripts/two-half-tabs'
// import fancy from './scripts/fancybox'
// import hashNav from './scripts/hash-navigation'
// import detailsToggle from './scripts/details-toggle'
import coverHeight from './scripts/cover-image-observer'
import optionalFields from './scripts/optional-fields-form'

// initialize things like header, footer
const oneTimeThings = () => {
  // svg sprites support in IE11
  svg4everybody()

  dropdown()
  optionalFields()

  const digitalExposePopup = document.getElementById('digital-expose-popup')
  if (digitalExposePopup) {
    form(digitalExposePopup)
  }
}

// initialize things inside of data-barba="container"
const onPageLoad = page => {
  // object-fit support in IE11
  objectFitImages()

  form(page)

  gsapAnimations('[data-barba="container"]')
  dropdown(page)
  videoObserver('[data-barba="container"]')

  activateModalForm(page)

  coverHeight(page)
}

document.addEventListener('DOMContentLoaded', () => {
  oneTimeThings()

  const container = document.querySelector('[data-barba="container"]')
  loadingAnimation()
  onPageLoad(container)
})
